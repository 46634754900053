import React from "react"
import Seo from "@root/Seo"
import Footer from "@root/Footer"
import BackgroundCodes from "@elements/BackgroundCodes"
import loadable from "@loadable/component"
import RelatedReferenzen from "@modules/referenzen/RelatedReferenzen"
import { graphql } from "gatsby"

export default function ReferenzTemplate({ data }) {
  const post = data.main.edges[0].node
  const { seo } = post
  return (
    <>
      <Seo
        title={`${seo.title || post.title}`}
        description={`${seo.metaDesc || ""}`}
      />
      {post.blocks.map((keyName, i) => {
        if (keyName.__typename.includes("WpCore")) return
        const LoadableModuleBlock = loadable(() =>
          import(`@modules/referenzen/${keyName.__typename}`)
        )
        return (
          <LoadableModuleBlock
            featuredImage={post.featuredImage}
            moduleData={keyName}
            header
            key={`${keyName.__typename}-${i}`}
          />
        )
      })}
      <RelatedReferenzen exclude={post.slug} />
      <BackgroundCodes />
      <Footer footerOptions={{}} />
    </>
  )
}

export const query = graphql`
  query ($post: String) {
    main: allWpReferenz(filter: { id: { eq: $post } }) {
      edges {
        node {
          title
          uri
          slug
          databaseId
          id
          seo {
            metaDesc
            title
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    quality: 90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          blocks {
            __typename
            ... on WpAcfTextImageRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefImageTextBlock {
                description
                descriptionColor
                endSymbol
                headline
                headlineColor
                imagePositionVariant
                overline
                overlineColor
                startSymbol
                link {
                  target
                  title
                  url
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1500
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                secondImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 700
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            ... on WpAcfVideoImageRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefVideoImageBlock {
                type
                embedId
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 2200
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                loopedVideo {
                  publicUrl
                }
                arrowsColor
                fullImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 2200
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            ... on WpAcfHeaderRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefHeaderBlock {
                description
                headline
                startSymbol
                endSymbol
              }
            }
            ... on WpAcfPatternBgRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefPatternTextImageBlock {
                backgroundColor
                backgroundVariant
                description
                descriptionColor
                endSymbol
                headline
                headlineColor
                link {
                  target
                  title
                  url
                }
                startSymbol
                overline
                overlineColor
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 2200
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1500
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                secondImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1500
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            ... on WpAcfQuoteRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefQuoteBlock {
                content
                quoteCredit
                symbolsColor
              }
            }
            ... on WpAcfSliderRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefSliderBlock {
                slides {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 2200
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            ... on WpAcfGalleryRefBlock {
              attributes {
                anchor
                className
                id
              }
              acfRefGalleryBlock {
                images {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
